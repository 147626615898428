

export const useSnackBarStore = defineStore('snackbarStore', {
    state: () => ({
        messages: [] as object[],
    }),
    actions: {
        addMessage(message: object) {
            this.messages.push(message)
        }
    }
})  